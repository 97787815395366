<template>
    <div class="bg-1">
        <div class="container full-height p-4 fl-y-cc">
            <s-form role="form" @submit="onSubmit" class="card p-4 bs-5"
                    style="width: 500px;" v-if="ShowLogin">
                <h3 class="auth-header">Login</h3>
                <validated-mask-input mask="##########" v-model="login.mobile" type="text" label=""
                                      name="Mobile Number" :rules="rules.common" class="field-border" placeholder="Enter your Mobile number">
                    <template #append>
                        <btn color="primary" size="md" :loading="loading" loading-text="Sending OTP..."
                             text="Login"/>

                    </template>
                </validated-mask-input>
            </s-form>

            <s-form role="form" class="card p-4 bs-5"
                    style="width: 500px;" v-if="newUser">
                <h3 class="auth-header">Register</h3>
                <validated-mask-input mask="##########" v-model="model.mobile" type="text" label="" placeholder="Enter your Mobile Number"
                                      name="Mobile Number" :rules="rules.common" :disabled="newUser" hidden class="field-border">
                </validated-mask-input>
                <validated-input name="Name" label="" placeholder="Enter your name"
                                 v-model="model.name" :rules="rules.common" class="field-border"/>
                <div class="mt-3 text-center">
                    <btn color="primary mr-2" class="px-5" @click="RegisterUser" :loading="regLoading" loading-text="Saving..."
                         text="Register"/>
                </div>
            </s-form>

            <s-form role="form" @submit="VerifyOTP" class="card p-4 bs-5"
                    style="width: 500px;" v-if="OTPField">
                <h3 class="auth-header">Verification</h3>
                <!--                <small>Please enter your OTP</small>-->
                <validated-mask-input mask="##########" v-model="verify.mobile" type="text" label="" placeholder="Enter your Mobile Number"
                                      :disabled="OTPField" hidden class="field-border"/>
                <validated-mask-input mask="##########" v-model="verify.otp" type="text" label=""
                                      :rules="rules.common" class="field-border" placeholder="Please enter your OTP"/>
                <div class="bg-danger p-3" style="text-align: center; border-radius: 5px;" v-if="errorMessage"
                     v-html="errorMessage"></div>
                <div class="mt-3 text-center">
                    <btn color="primary mr-2" :loading="OTPLoading" loading-text="Validating Credential.."
                         text="Verify" class="px-5"/>
                </div>
            </s-form>
        </div>
    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapActions } from 'vuex';

export default {
    name : 'Login',
    data () {
        return {
            ShowLogin    : '',
            loading      : false,
            regLoading   : false,
            OTPLoading   : false,
            errorMessage : '',
            newUser      : false,
            OTPField     : false,
            login_status : false,
            login        : {
                mobile : ''
            },
            model : {
                mobile : '',
                name   : ''
            },
            verify : {
                mobile : '',
                otp    : ''
            },

            rules : {
                common : {
                    required : true
                }
            }
        };
    },
    beforeMount () {
        this.ShowLogin = true;
    },
    methods : {
        ...mapActions(['setUser']),
        async onSubmit () {
            const that = this;
            this.loading = true;
            const response = await axios.form(urls.auth.send_otp, that.login);
            console.log('onSubmit-response', response.data);
            if (response.data.success === true) {
                // alert('Success');
                this.ShowLogin = false;
                this.OTPField = true;
                this.login_status = true;
            } else if (response.data.message === 'User does not exists') {
                alert('User does not exists');
                this.ShowLogin = false;
                this.newUser = true;
            }
        },
        async RegisterUser () {
            alert('Register');
            const that = this;
            this.regLoading = true;
            that.model.mobile = that.login.mobile;
            const response = await axios.form(urls.auth.NewUser, that.model);
            console.log('register-response', response.data.success);
            if (response.data.success === true) {
                this.newUser = false;
                this.ShowLogin = false;
                this.OTPField = true;
            }
        },
        VerifyOTP () {
            const that = this;
            this.OTPLoading = true;
            that.verify.mobile = that.login.mobile;
            if (this.login_status) {
                axios.form(urls.auth.login, that.verify).then(function (response) {
                    const json = response.data;
                    console.log('Login-json', json);
                    if (json.success) {
                        const redirectUrl = sessionStorage.getItem('redirectPath');
                        that.setUser(json.data);
                        that.$router.push(redirectUrl || { path : '/' });
                    } else {
                        that.errorMessage = json.message || '';
                    }
                    that.OTPLoading = false;
                }).catch(function () {
                    that.errorMessage = 'Invalid credentials.';
                    that.OTPLoading = false;
                });
            } else {
                axios.form(urls.auth.NewUser_verify,
                    {
                        name   : that.model.name,
                        mobile : that.verify.mobile,
                        otp    : that.verify.otp
                    }).then(function (response) {
                    const json = response.data;
                    console.log('Login-json', json);
                    if (json.success) {
                        const redirectUrl = sessionStorage.getItem('redirectPath');
                        that.setUser(json.user);
                        that.$router.push(redirectUrl || { path : '/' });
                    } else {
                        that.errorMessage = json.message || '';
                    }
                    that.OTPLoading = false;
                }).catch(function () {
                    that.errorMessage = 'Invalid credentials.';
                    that.OTPLoading = false;
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
